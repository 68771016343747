<template>
  <div class="integralMerchandiseManagement">
    <ds-header title="积分商品管理"></ds-header>
    <a-form layout="inline" :labelCol="{ style: 'width: 90px' }">
      <a-form-item label="商品名称：">
        <a-input allow-clear v-model.trim="searchForm.productName" placeholder="请输入商品名称" class="searchInput" />
      </a-form-item>
      <a-form-item label="商品编号：">
        <a-input allow-clear v-model.trim="searchForm.productNo" placeholder="请输入商品编号" class="searchInput" />
      </a-form-item>
      <a-form-item label="商品类型：">
        <a-select
          v-model="searchForm.productType"
          placeholder="请选择商品类型"
          allow-clear
          class="searchInput"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="option in productTypeOptions"
            :key="option.commonsValue"
            :value="option.commonsValue"
            >{{ option.commonsValueName }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="商品状态：">
        <a-select
          v-model="searchForm.productState"
          allow-clear
          class="searchInput"
          placeholder="请选择商品状态"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="option in productStateOptions"
            :key="option.commonsValue"
            :value="option.commonsValue"
            >{{ option.commonsValueName }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="商品排序：">
        <a-select
          v-model="searchForm.sort"
          class="searchInput"
          placeholder="请选择商品排序"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in productSortOptions" :key="option.id" :value="option.id">{{
            option.value
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="创建人：">
        <a-input allow-clear v-model.trim="searchForm.nickName" placeholder="请输入创建人" class="searchInput" />
      </a-form-item>
      <a-form-item label="归属项目：">
        <a-select
          v-model="searchForm.projectId"
          class="searchInput"
          placeholder="请选择项目"
        >
          <a-select-option v-for="item in projectList" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="规格类型：">
        <a-select
          v-model="searchForm.specificationType"
          placeholder="全部"
          allow-clear
          class="searchInput"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="option in specificationTypes"
            :key="option.value"
            :value="option.value"
          >{{ option.label }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item>
        <ds-search @search="search" @reset="reset"></ds-search>
      </a-form-item>
    </a-form>
    <div style="padding-top: 15px">
      <ds-button
        type="export"
        @click="exportFile"
        v-if="
          $checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.integralMerchandiseManagement.export)
        "
      ></ds-button>
      <ds-button
        title="创建自营商品"
        @click="createGoods"
        style="margin-left: 15px"
        v-if="
          $checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.integralMerchandiseManagement.add)
        "
      ></ds-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="productCoverImg" slot-scope="text, record" class="action">
          <div class="imgContainer">
            <img :src="record.productCoverImg" />
            <span>{{ record.productLabel }}</span>
          </div>
        </span>
        <span slot="productState" slot-scope="text, record" class="action">
          <span>{{ record.freezeType === 1 ? "冻结" : record.productState  === 0 ? '下架' : record.productState === 1 ? '上架' : '-' }}</span>
        </span>
        <span slot="productName" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">{{ record.productName }}</template>
            <span class="field">{{ record.productName }}</span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button
            style="padding: 3px"
            type="link"
            @click="view(text, record)"
            >查看</a-button
          >
          <a-button
            style="padding: 3px"
            type="link"
            :disabled="record.productState === 1"
            v-if="
              $checkButtonPermissions(
                $buttonPermissionsConfig.integralMallManagement.integralMerchandiseManagement.edit
              )
            "
            @click="edit(text, record)"
            >修改</a-button
          >
          <a-button
            style="padding: 3px"
            type="link"
            @click="upperOrLower(record)"
            v-if="
              $checkButtonPermissions(
                $buttonPermissionsConfig.integralMallManagement.integralMerchandiseManagement.upDownShelf
              )
            "
            >{{ record.productState === 0 ? "上架" : "下架" }}</a-button
          >
          <a-button
            style="padding: 3px"
            type="link"
            @click="freeze(record)"
            v-if="
              $checkButtonPermissions(
                $buttonPermissionsConfig.integralMallManagement.integralMerchandiseManagement.freeze
              )
            "
            >{{ record.freezeType === 0 ? "冻结" : "解冻" }}</a-button
          >
          <a-button
            style="padding: 3px"
            type="link"
            @click="copyData(record.id)"
          >复制</a-button
          >
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as api from "@/api/integralGoods";
import PagingConfig from "@/utils/pagingConfig";
import moment from "moment";
export default {
  name: "integralMerchandiseManagement",
  components: {},
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
      projectList: (state) => state.common.projectList
    }),
  },
  data() {
    return {
      searchForm: {
        // 商品名称
        productName: null,
        // 商品编号
        productNo: null,
        // 商品状态
        productState: undefined,
        // 商品类型
        productType: undefined,
        // 商品排序
        sort: 1,
        // 创建人
        nickName: null,
        // 所属项目
        projectId: undefined,
        // 规格类型
        specificationType: undefined
      },
      // 商品类型选项
      productTypeOptions: [],
      // 规格类型
      specificationTypes: [
        {
          label: '单规格',
          value: 0
        },
        {
          label: '多规格',
          value: 1
        }
      ],
      // 商品状态选项
      productStateOptions: [],
      // 商品排序选项
      productSortOptions: [
        {
          id: 1,
          value: "创建时间",
        },
        {
          id: 3,
          value: "销量",
        },
      ],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "封面图片",
            dataIndex: "productCoverImg",
            key: "productCoverImg",
            scopedSlots: { customRender: "productCoverImg" },
            width: 112,
            align: "center",
          },
          {
            title: "商品名称",
            dataIndex: "productName",
            key: "productName",
            scopedSlots: { customRender: "productName" },
            align: "center",
          },
          {
            title: "商品编号",
            dataIndex: "productNo",
            key: "productNo",
            align: "center",
          },
          {
            title: "商品类型",
            dataIndex: "productType",
            key: "productType",
            align: "center",
            customRender(text) {
              return text === 0 ? '实物' : text === 1 ? '羊毛券' : '第三方串码'
            }
          },
          {
            title: "规格类型",
            dataIndex: "specType",
            key: "specType",
            align: "center",
            customRender: (text) => {
              return text === 1 ? '多规格' : '单规格';
            }
          },
          {
            title: "归属项目",
            dataIndex: "productProject",
            key: "productProject",
            align: "center",
            width: '230px'
          },
          {
            title: "商品价格",
            dataIndex: "productPrice",
            key: "productPrice",
            customRender: (text) => {
              return `¥ ${text}`;
            },
            align: "center",
          },
          {
            title: "所需分值",
            dataIndex: "productExchangePrice",
            key: "productExchangePrice",
            align: "center",
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            align: "center",
            customRender(text) {
              return moment(text).format('YYYY-MM-DD HH:mm:ss')
            }
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            align: "center",
            customRender(text) {
              return moment(text).format('YYYY-MM-DD HH:mm:ss')
            }
          },
          {
            title: "创建人",
            dataIndex: "createName",
            key: "createName",
            scopedSlots: { customRender: "createName" },
            align: "center",
          },
          {
            title: "商品状态",
            dataIndex: "productState",
            key: "productState",
            scopedSlots: { customRender: "productState" },
            align: "center",
          },
          {
            title: "可销库存",
            dataIndex: "marketAbilityStock",
            key: "marketAbilityStock",
            align: "center",
          },
          {
            title: "已售数量",
            dataIndex: "marketNumber",
            key: "marketNumber",
            align: "center",
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 列表操作按钮权限配置
      actions: [
        this.$buttonPermissionsConfig.integralMallManagement.integralMerchandiseManagement.edit,
        this.$buttonPermissionsConfig.integralMallManagement.integralMerchandiseManagement.upDownShelf,
        this.$buttonPermissionsConfig.integralMallManagement.integralMerchandiseManagement.freeze,
      ],
    };
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction({width: "200px", align: "center"}));
    this.loadProductList("product_state");
    this.loadProductList("product_type");
    this.getIntegralGoodsList(this.searchForm, this.table.pagination.current, this.table.pagination.pageSize);
  },
  methods: {
    // 获取商品类型项目
    async loadProductList(id) {
      if (id === "product_state") {
        const res = await api.getProductList(id);
        this.productStateOptions = res.data;
      } else {
        const res = await api.getProductList(id);
        this.productTypeOptions = res.data;
      }
    },
    // 查询
    search() {
      this.refreshData();
    },
    // 重置
    reset() {
      this.searchForm = {
        // 商品名称
        productName: null,
        // 商品编号
        productNo: null,
        // 商品状态
        productState: undefined,
        // 商品类型
        productType: undefined,
        // 商品排序
        sort: 1,
        // 创建人
        nickName: null,
      };
      this.refreshData();
    },
    // 导出
    exportFile() {
      const _this = this;
      this.$confirm({
        title: `确定要导出文件吗？`,
        async onOk() {
          try {
            const res = await api.exportProduct(_this.searchForm);
            const blob = new Blob([res.data]);
            const objectUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = objectUrl;
            a.setAttribute("download", "积分商品管理.xlsx");
            a.click();
          } catch (e) {
            _this.$message.error("导出失败，请联系管理员");
          }
        },
        onCancel() {},
        class: "test",
      });
    },
    // 创建自营商品
    createGoods() {
      this.$router.push({
        path: "/integralMallManagement/integralMerchandiseManagement/detail",
        query: {
          type: 'add'
        }
      });
    },
    // 编辑
    edit(text, record) {
      this.$router.push({
        path: "/integralMallManagement/integralMerchandiseManagement/detail",
        query: {
          id: record.id,
          type: 'edit'
        },
      });
    },
    // 查看
    view(text, record) {
      this.$router.push({
        // path: "/integralMallManagement/integralMerchandiseManagement/edit",
        path: "/integralMallManagement/integralMerchandiseManagement/detail",
        query: {
          id: record.id,
          type: 'view'
        },
      });
    },
    // 上下架
    upperOrLower(record) {
      const _this = this;
      this.$confirm({
        title: `确定要${record.productState === 1 ? "下架" : "上架"}吗？`,
        async onOk() {
          const res = await api.updateStatus(record.id, record.productState === 1 ? 0 : 1);
          if (res.code === "200") {
            _this.$message.success(`${record.productState === 1 ? "下架" : "上架"}成功`);
            _this.refreshData(_this.table.pagination.current);
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
        class: "test",
      });
    },
    // 解冻/冻结
    freeze(record) {
      const _this = this;
      this.$confirm({
        title: `确定要${record.freezeType === 0 ? "冻结" : "解冻"}吗？`,
        async onOk() {
          const res = await api.updateFrozen(record.id, record.freezeType === 0 ? 1 : 0);
          if (res.code === "200") {
            _this.$message.success(`${record.freezeType === 0 ? "冻结" : "解冻"}成功`);
            _this.refreshData(_this.table.pagination.current);
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
        class: "test",
      });
    },
    // 分页操作
    pageChange(option) {
      this.refreshData(option.current, option.pageSize);
    },

    /** 网咯请求 */
    // 获取积分商品列表
    async getIntegralGoodsList(data, page, size) {
      try {
        const result = await api.getIntegralGoodsList(data, page, size);
        const { code, msg, data: { items, total } } = result
        if (code === "200") {
          this.table.dataInfo = items;
          this.table.pagination.total = total;
        } else {
          this.$message.error(msg);
        }
      } catch (error) {}
    },

    // 刷新data
    refreshData(current = PagingConfig.defaultPage, pageSize = PagingConfig.defaultPageSize) {
      if (pageSize !== this.table.pagination.pageSize) {
        this.table.pagination.current = PagingConfig.defaultPage;
      } else {
        this.table.pagination.current = current;
      }
      this.table.pagination.pageSize = pageSize;
      this.getIntegralGoodsList(this.searchDataTidy(), current, pageSize);
    },
    // 查询数据处理
    searchDataTidy() {
      const searchData = {
        ...this.searchForm,
      };
      if (searchData.productType === null) {
        searchData.productType = "";
      }
      if (searchData.productState === null) {
        searchData.productState = "";
      }
      return searchData;
    },
    // 复制数据
    async copyData(id) {
      const { code, data } = await api.productDetail(id)
      if (code === "200") {
        sessionStorage.setItem("productData",JSON.stringify(data));
        this.$message.success("复制成功！")
      } else {
        this.$message.error("复制失败！")
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.searchInput {
  width: 200px;
}
.imgContainer {
  width: 80px;
  height: 80px;
  position: relative;
  text-align: center;
  background-color: lightgray;
  img {
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 80px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: #fff;
    font-size: 12px;
    line-height: 20px;
  }
}
</style>
